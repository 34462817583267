.marker {
  width: 0;
  height: 0;
  pointer-events: none;
  &>div {
    background-repeat: no-repeat;
    bottom: 0;
    transform: translateX(-50%);
    position: absolute;
    &.empty {
      [tooltip]::before,
      [tooltip]::after {
        font-family: 'Open Sans', serif;
        font-size: 12px;
        line-height: 1;
        user-select: none;
        pointer-events: none;
        position: absolute;
        display: block;
        opacity: 1;
      }
      [tooltip]::before {
        content: '';
        border: 6px solid transparent;
        z-index: 1001;
      }
      [tooltip]::after {
        content: attr(tooltip);
        text-align: center;
        min-width: 1em;
        max-width: 10em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0.75ch 1ch;
        border-radius: 0.75ch;
        border: 2px solid white;
        background: green;
        color: #fff;
        z-index: 1000;
      }

      [tooltip]:not([flow])::before,
      [tooltip]::before {
        bottom: 100%;
        border-bottom-width: 1px;
        border-top-color: green;
      }
      [tooltip]:not([flow])::after,
      [tooltip][flow^="up"]::after {
        bottom: calc(100% + 5px);
      }
      [tooltip]:not([flow])::before,
      [tooltip]:not([flow])::after,
      [tooltip][flow^="up"]::before,
      [tooltip][flow^="up"]::after {
        left: 50%;
        transform: translate(-50%, -.5em);
      }

      &.gray {
        [tooltip]::after {
          background: grey;
        }
        [tooltip]::before {
          border-top-color: grey;
        }
      }
      &.green {
        [tooltip]::after {
          background: #177146;
        }
        [tooltip]::before {
          border-top-color: #177146;
        }
      }
      &.red {
        [tooltip]::after {
          background: #EA4335;
        }
        [tooltip]::before {
          border-top-color: #EA4335;
        }
      }
      &.yellow {
        [tooltip]::after {
          background: #FB9417;
        }
        [tooltip]::before {
          border-top-color: #FB9417;
        }
      }
    }
  }
}

.boxdraw {
  background: rgba(255, 240, 0, 0.2);
  border: 4px solid #FF7417;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}